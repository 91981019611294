import styled from 'styled-components'
import tw from 'twin.macro'

export const Root = styled.div`
  ${tw`py-insetY px-insetX`};
`

export const Links = styled.div`
  ${tw`grid grid-cols-3 gap-x-4`};
`

export const LinkWrapper = styled.div`
  ${tw`block`};
`
