import { capitalCase } from 'change-case'
import Head from 'next/head'
import { useRouter } from 'next/router'

import siteNavigation from 'data/siteNavigation.json'
import siteSubNavigation from 'data/siteSubNavigation.json'

import Divider from 'components/Divider'
import Header from 'components/Header'
import Link from 'components/Link'
import Spacer from 'components/Spacer'
import Text from 'components/Text'

import { LinkWrapper, Links, Root } from './404.style'

// TODO fix this when viewing a product under a collection
const isJournalPath = (path) => /^\/journal/i.test(path)
const isCollectionPath = (path) => /^\/collection\//i.test(path)
const isProductPath = (path) => /\/(collections|products)\//i.test(path)

const determineSubject = (path) => {
  if (isJournalPath(path)) return 'article'
  if (isCollectionPath(path)) return 'collection'
  if (isProductPath(path)) return 'product'

  return 'page'
}

export default function Custom404() {
  const router = useRouter()
  const subject = determineSubject(router.asPath)

  const articleCategories = siteSubNavigation?.articleCategories?.map((articleCategory) => ({
    href: `/journal/sections/${articleCategory.uid}`,
    text: articleCategory.data.title,
  }))

  const header = `${capitalCase(subject)} Not Found`

  return (
    <>
      <Head>
        <title>{header} - The Armoury</title>
      </Head>
      <Root>
        <Header>{header}</Header>
        <Spacer y={4} />
        <Text>Sorry, we can’t find the {subject} you’re looking for. Here are some useful links:</Text>
        <Spacer y={8} />
        <Divider />
        <Spacer y={8} />
        <Links>
          <div>
            <Text weight="bold">Shopping</Text>
            {siteNavigation?.primary?.map(({ text, href }) => (
              <LinkWrapper key={href}>
                <Link href={href} animated>
                  {text}
                </Link>
              </LinkWrapper>
            ))}
          </div>
          <div>
            <Text weight="bold">Journal</Text>
            {articleCategories?.map(({ text, href }) => (
              <LinkWrapper key={href}>
                <Link href={href} animated>
                  {text}
                </Link>
              </LinkWrapper>
            ))}
          </div>
          <div>
            <Text weight="bold">Customer Advice</Text>
            {siteNavigation?.['customer-advice']?.map(({ text, href }) => (
              <LinkWrapper key={href}>
                <Link href={href} animated>
                  {text}
                </Link>
              </LinkWrapper>
            ))}
          </div>
        </Links>
        <Spacer y={8} />
        <Divider />
      </Root>
    </>
  )
}
